import {field} from "../../../core/models/fields/Field";
import {Model} from "../../../core/models/Model";
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {TemplateOptionTag} from './TemplateOptionTag';
import {Collection} from '../../../core/models/Collection';
import {APIResource} from '../../../core/models/APIResource';

export enum TemplateOptionChargeOption {
    CHARGE_PERCENTAGE = 0,
    CHARGE_TOTAL = 1,
    CHARGE_UNIT = 2
}

export class TemplateOption extends Model {
    static override uri: string = '/custom/api/v1/template-option/:id/';
    static override objects: APIResource<TemplateOption>;

    @field()
    charge_option: TemplateOptionChargeOption;

    @field()
    help_text: string;

    @field()
    image: string;

    @field()
    name: string;

    @field()
    amount: number;

    @field()
    currency: string;

    @field()
    percent: number;

    @field()
    value_csv: string[];

    @field()
    wholesale_amount: number;

    @field()
    wholesale_amount_csv: string[];

    @field()
    option_value: string;

    @field(ToManyField, {
        model: 'TemplateOptionTag',
        readOnly: true
    })
    tags: Collection<TemplateOptionTag>;

    @field()
    default: boolean;

    public display_value(show_retail: boolean = false, quantity_break_index: number = null): Number {
        if (this.charge_option == TemplateOptionChargeOption.CHARGE_PERCENTAGE) {
            return Number(this.percent);
        }

        if (!show_retail && this.wholesale_amount) {
            if (quantity_break_index != null && this.wholesale_amount_csv && this.wholesale_amount_csv.length > 0) {
                if (quantity_break_index > this.wholesale_amount_csv.length) {
                    quantity_break_index = this.wholesale_amount_csv.length - 1;
                }

                return Number(this.wholesale_amount_csv[quantity_break_index]);
            }

            return Number(this.wholesale_amount);
        }

        if (quantity_break_index != null && this.value_csv && this.value_csv.length > 0) {
            if (quantity_break_index > this.value_csv.length) {
                quantity_break_index = this.value_csv.length - 1;
            }

            if (show_retail) {
                return Number(this.value_csv[quantity_break_index]) * (1/0.6);
            }

            return Number(this.value_csv[quantity_break_index]);
        }

        if (show_retail) {
            return Number(this.amount) * (1/0.6);
        }

        return Number(this.amount);
    }

    public hasTag(slug: string): boolean {
        for (const tag of this.tags) {
            if (tag.slug == slug) {
                return true;
            }
        }
        return false;
    }

    get value() {
        console.error('VALUE GET');
        console.trace();

        return null;
    }
    set value(v) {
        console.error('VALUE SET');
        console.trace();
    }
}
