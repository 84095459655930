export const html:string = `
<div class="recommendation-base" id="trending-products-component">
    <h2>Trending Products</h2>
    
    <div class="carousel-slider" v-show="!show_more">
        <div class="slider-container">
            <ul class="carousel-slider no-scrollbar">
                <li v-if="products" v-for="product in products.items" class="related-product-item">
                    <a :href="product.url" :title="product.name" class="product-image">
                        <picture>
                            <source v-if="product.thumbnail_webp" :srcset="product.thumbnail_webp" type="image/webp">
                            <img :src="product.thumbnail" :alt="product.name"/>
                        </picture>
                    </a>
                    <div class="product-details">
                        <a :href="product.url" class="product-name" :title="product.name">
                            {{ product.name }}
                        </a>
                    </div>
                </li>
            </ul>
        </div>
        <span class="glyphicon glyphicon-chevron-left" @click="carousel_controller.scrollSliderLeft($event)" aria-hidden="true" :class="{'disabled': carousel_controller.maxLeft()}"></span>
        <span class="glyphicon glyphicon-chevron-right" @click="carousel_controller.scrollSliderRight($event)" aria-hidden="true" :class="{'disabled': carousel_controller.maxRight()}"></span>
    </div>
</div>
`;
