import VueApp from '../../ts/core/adapters/VueApp';
import registerCoreComponents from '../../ts/core/components/init';
import registerProductCoreAddonComponents from '../../ts/apps/products_shared/components/init';
import registerProductAddonComponents from '../../ts/apps/products_shared/components/products.init';
import {Services} from '../../ts/core/services/Services';
import {IntegrationExtra} from '../../ts/core/utils/IntegrationExtra';
import ProductFAQListApp from '../../ts/apps/faqs/apps/ProductFAQList';


export default class SharedProductComponentsIntegration extends IntegrationExtra {
    setupApps(): void {
        const nav_app = new VueApp();
        registerCoreComponents(nav_app);
        registerProductAddonComponents(nav_app);
        nav_app.mount('#navigator');

        const recommended_products_app = new VueApp();
        registerCoreComponents(recommended_products_app);
        registerProductCoreAddonComponents(recommended_products_app);
        registerProductAddonComponents(recommended_products_app)
        recommended_products_app.mount('#browse-recommended-products-app');

        const trending_products_app = new VueApp();
        registerCoreComponents(trending_products_app);
        registerProductCoreAddonComponents(trending_products_app);
        registerProductAddonComponents(trending_products_app)
        trending_products_app.mount('#trending-products-app');

        // These depend on loading the product js file with the models and services
        Services.waitOn('loaded-page-app').then(() => {
            const related_product_app = new VueApp();
            registerCoreComponents(related_product_app);
            registerProductCoreAddonComponents(related_product_app);
            registerProductAddonComponents(related_product_app);
            related_product_app.mount('#related-products-app');

            const recommended_product_app = new VueApp();
            registerCoreComponents(recommended_product_app);
            registerProductCoreAddonComponents(recommended_product_app);
            registerProductAddonComponents(recommended_product_app);
            recommended_product_app.mount('#recommended-products-app');
        });
    }

    setupModels(): void {
    }

    setupServices(): void {
    }
}
