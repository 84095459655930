import {QuantityType} from '../../../svgcustom/models/abstract/ConfiguredProductAbstract';
import {SymbolType} from '../../../svgcustom/models/enums';

export const html: string = `
<div class="input-area quantity-component template-setup-options-quantity" v-if="full">
    <div class="range-group" v-if="productService.configured.quantity_type === ${QuantityType.RANGE} && productService.configured.quantity_model">
        <div v-if="productService.configured.quantity_model.symbol === ${SymbolType.NUMBER_RANGE}">
            <label class="options-label">Number Range</label>
            <error-list field="range" :errors="productService.configured.quantity_model.errors"></error-list>
            <error-list class="warning" field="quantity" :errors="productService.warnings"></error-list>
            
            <div class="range-input-group" :class="{stacked: stackedRange()}">
                <div class="width-group">
                    <div class="label-group">
                        <label for="range-start">From</label>
                    </div>
                    <input class="form-control large-size-input" type="text"
                           v-model="productService.configured.quantity_model.symbol_range_start" 
                           id="range-start" @keyup="productService.updatePricing(); productService.updateCanvas()" required>
                </div>
                <div class="height-group">
                    <div class="label-group">
                        <label for="range-end">To</label>
                    </div>
                    <input class="form-control large-size-input" type="text"
                           v-model="productService.configured.quantity_model.symbol_range_end" 
                           id="range-end" @keyup="productService.updatePricing()" required>
                </div>
                <div class="proportions" v-if="!disableRangeRemoval">
                    <div class="btn-group">
                        <button v-tooltip :tooltip-content="'Remove Variable Data'" aria-label="Remove Variable Data" class="btn proportions-btn" @click="productService.removeQuantityModel()"><i class="fa fa-trash"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="productService.configured.quantity_model.symbol === ${SymbolType.CSV_LIST}">
            <label class="options-label">Comma Separated List:</label>
            <error-list field="range" :errors="productService.configured.quantity_model.errors"></error-list>
            <error-list class="warning" field="quantity" :errors="productService.warnings"></error-list>
            <div class="quantity">
                <div class="quantity-line">
                    <textarea type="text" class="form-control" v-model="productService.configured.quantity_model.text" v-show="productService.configured.quantity_model.variable_data_editable"></textarea>
                </div>
            </div>
            <div class="remove-option" v-if="!disableRangeRemoval">
                <button class="btn proportions-btn flex-full full-width" @click="productService.removeQuantityModel()">
                    <i class="fa fa-trash"></i> Remove Variable Data
                </button>
            </div>
        </div>
    </div>
    <div class="quantity-group" v-if="!hidePrice">
        <div class="options-label" v-if="productService.configured.quantity_type === ${QuantityType.RANGE}">Quantity:</div>
        <div class="options-label" v-if="!(productService.configured.quantity_type === ${QuantityType.RANGE})">Select Quantity:</div>
        <error-list field="quantity" :errors="productService.errors"></error-list>
        <error-list class="warning" field="quantity" :errors="productService.warnings"></error-list>
        <div class="quantity">
            <div id="qty-input" class="quantity-line">
                <input aria-label="Quantity" type="text" class="form-control" id="id-quantity" placeholder="Quantity" v-model="productService.quantity" :disabled="productService.configured.defined_quantity" min="1" step="1">
            </div>
            <div class="quantity-value">
                <div class="custom-price">
                    <span v-if="productService.quantity < 2">{{ $filters.currency(productService.price.price, productService.configured.currency) }}</span> 
                    <span class="unit-price" v-show="productService.quantity > 1">{{ $filters.currency(productService.price.unitPrice + productService.optionsUnitValue, productService.configured.currency) }} </span> Each
                </div>
            </div>
        </div>
    </div>
</div>
<div v-if="!productService.configured.defined_quantity && !hidePrice" class="template-setup-options-quantity quantity-options quantity-component">
    <div class="table-container">
        <table class="options table">
            <tbody>
                <tr v-for="tier in quantity_options" @click="productService.quantity = tier.quantity">
                    <td class="td-input">
                        <input type="radio" :value="tier.quantity" name="popular-quantities" v-model="productService.quantity" :id="tier.label" />
                    </td>
                    <td>
                        <label :for="tier.label">{{ tier.quantity }}</label>
                    </td>
                    <td>
                        <label :for="tier.label">
                            <span v-if="tier.quantity < 2">{{ $filters.currency(tier.price, productService.configured.currency) }}</span>
                            <span v-if="tier.quantity > 1">{{ $filters.currency(tier.unitPrice, productService.configured.currency) }}</span> each
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="more-price-breaks" @click.prevent="show_more_quantities = !show_more_quantities; updateQuantityOptions()" v-show="show_more_qty_options" aria-label="See More Options">
            <div class="icon-group">
                <hr><i class="far fa-chevron-double-down" :class="{reverse: show_more_quantities}"></i><hr>
            </div>
        </button>
    </div>
</div>
`;