/*
    Vue's reactivity system can slow things down a lot so these function will optionally disable the system.
 */
import {ReactiveFlags} from '@vue/reactivity';

export function nonreactiveClass<T extends {new (...args: any[]): {}}>(constructor: T) {
    constructor.prototype[ReactiveFlags.SKIP] = true;
}

export function nonreactiveProperty() {
    return function(target: any, key: string) {
        Object.defineProperty(target, key, {
            get: () => {
                return target[`___${key}`];
            },
            set: (v) => {
                if (v) {
                    v[ReactiveFlags.SKIP] = true;
                }

                target[`___${key}`] = v;
            },
            enumerable: false,
            configurable: false
        });
    };
}

export function disableVueForImport(v: any) {
    for (const key of Object.keys(v)) {
        let item = v[key];

        if (!item || !(item instanceof Object) || !item.prototype) {
            continue
        }

        item.prototype[ReactiveFlags.SKIP] = true;
    }
}
