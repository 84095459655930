import VueComponent, {data} from '../../../../core/adapters/VueComponent';
import {Http} from '../../../../core/services/Http';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {html} from './TrendingProducts.html';
import CarouselController from '../../../../core/utils/CarouselController';

export class TrendingProductsController extends VueComponent {
    static override $inject = [
        '$http',
    ];

    @data()
    products: any;

    @data()
    show_more: boolean;

    @data()
    carousel_controller: CarouselController;
    constructor(component, protected $http: Http) {
        super(component);

        this.carousel_controller = new CarouselController(this);

        this.$http.request({
            method: 'GET',
            params: {},
            url: '/order/api/user/trending-products/'
        }).then((response) => {
            this.products = response.data;
        });
    }

    override mounted() {
        super.mounted();
        this.carousel_controller.mounted();
    }

    override unmounted() {
        super.unmounted();
        this.carousel_controller.unmounted();
    }
}

export default function TrendingProducts(): IVueComponent {

    return {
        controller: TrendingProductsController,
        template: html,
        tag: 'trending-products'
    };
}
