import {html} from './RecommendedProductList.html';
import {RecommendedProduct} from '../../../svgcustom/models/RecommendedProduct';
import APIResponse from '../../../../core/models/APIResponse';
import VueComponent, {data, method, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {Http} from '../../../../core/services/Http';
import CarouselController from '../../../../core/utils/CarouselController';

export class RecommendedProductListController extends VueComponent {
    static override $inject = [
        '$http',
        'RecommendedProduct'
    ];

    @prop()
    design: string;

    @prop()
    contentType: string;

    @prop()
    objectId: string;

    @prop()
    nameOverride: string;

    @prop()
    hideHr: string;

    @data()
    products: APIResponse<RecommendedProduct>;

    @data()
    customer_products: any[];

    @data()
    load_content: boolean;

    @data()
    groups: any;

    @data()
    carousel_controller: CarouselController;

    @prop()
    cartList: boolean;

    @prop()
    category: string;

    observer: any;
    constructor(component, protected $http: Http, protected RecommendedProductModel: typeof RecommendedProduct) {
        super(component);

        this.products = RecommendedProductModel.objects.all();
        this.products.$promise.then(this.sortRecommendedProducts.bind(this));
        this.customer_products = [];
        this.groups = {};
        this.load_content = false;

        if (this.objectId && this.contentType && this.design) {
            // If we are showing recommended products for a single item
            this.customer_products.push({
                objectId: this.objectId,
                contentType: this.contentType,
                design: this.design
            })
        }
        else {
            // If we are showing recommended products for the cart
            this.$http.request({
                url: '/custom/api/v1/cart-products-to-recommend/',
                method: 'GET',
            }).then((response) => {
                this.customer_products = response.data.data;
            });
        }

        this.carousel_controller = new CarouselController(this);
    }

    sortRecommendedProducts() {
        this.groups = {};

        for (const rec of this.products.items) {
            if (rec.group) {
                this.groups[rec.group.id] = this.groups[rec.group.id] ? this.groups[rec.group.id] : {group: rec.group, products: []};

                this.groups[rec.group.id].products.push(rec);
            }
        }
    }

    @method()
    customerProductForIndex(index) {
        return this.customer_products[index % this.customer_products.length];
    }

    override mounted() {
        /*
            This content is a bit expensive to load so lets only load it once the user scrolls down near it
         */

        // If IntersectionObserver is support the use it, if not lets just load the content and not worry about a polyfill
        if ('IntersectionObserver' in window) {
            let options = {
              root: null,
              rootMargin: '0px',
              threshold: 0
            }

            this.observer = new IntersectionObserver(this.loadContent.bind(this), options);
            this.observer.observe(this.$el);
        }
        else {
            this.loadContent(null, null);
        }

        this.carousel_controller.mounted();
    }

    override unmounted() {
        this.carousel_controller.unmounted();
    }

    loadContent(entry, observer) {
        if (this.load_content) {
            return;
        }
        if (!entry || entry[0].isIntersecting) {
            if (this.observer) {
                this.observer.unobserve(this.$el);
            }

            // Force a angular digest
            this.load_content = true;
        }
    }

    @method()
    isVisible() {
        return this.load_content && this.customer_products && this.customer_products.length > 0 && this.products.items.length > 0;
    }
}

export default function RecommendedProductList(): IVueComponent {

    return {
        controller: RecommendedProductListController,
        template: html,
        tag: 'recommended-product-list'
    };
}
