export const html: string = `
<div class="product-page-navigator">
    <nav class="navbar navbar-light absolute-nav product-page-nav-container" v-show="fixed">
        <ul class="navbar-nav" id="product-page-nav-list">
            <li v-for="item in active_selections" :class="{'active-nav-item': item.active}" @click="setActiveLink(item)">
                <button @click="setActiveLink(item)">
                    <h3>{{ item.base.element_title }}</h3>
                </button>
            </li>
        </ul>    
    </nav>
    <nav class="navbar navbar-light product-page-nav-container">
        <ul class="navbar-nav" id="product-page-nav-list">
            <li v-for="item in active_selections" :class="{'active-nav-item': item.active}" @click="setActiveLink(item)">
                <button @click="setActiveLink(item)">
                    <h3>{{ item.base.element_title }}</h3>
                </button>
            </li>
        </ul>    
    </nav>
</div>
`;