import VueComponent, {data, method, prop} from '../../../../core/adapters/VueComponent';
import APIResponse from '../../../../core/models/APIResponse';
import {RecommendedProduct} from '../../../svgcustom/models/RecommendedProduct';
import {getInnerHeight, getOffset} from '../../../../core/utils/utils';
import {Services} from '../../../../core/services/Services';

export abstract class RecommendedProductAbstract extends VueComponent {
    offset: number;
    viewTimeout;

    @data()
    groups: any;

    @prop()
    design: any;

    @prop()
    contentType: any;

    @prop()
    objectId: any;

    @data()
    customer_products: any[];

    @data()
    products: APIResponse<RecommendedProduct>;

    @prop()
    cart: boolean;

    constructor(component) {
        super(component);

        this.products = Services.get<typeof RecommendedProduct>('RecommendedProduct').objects.all();
        this.products.$promise.then(this.sortRecommendedProducts.bind(this));
        this.customer_products = [];

        this.offset = 24;
        this.loadProducts();

        window.addEventListener('resize', this.resize_or_scroll.bind(this));
        window.addEventListener('scroll', this.resize_or_scroll.bind(this));

        this.loadProducts();
    }

    sortRecommendedProducts() {
        this.groups = {};

        for (const rec of this.products.items) {
            if (rec.group) {
                this.groups[rec.group.id] = this.groups[rec.group.id] ? this.groups[rec.group.id] : {group: rec.group, products: []}

                this.groups[rec.group.id].products.push(rec);
            }
        }
    }

    override mounted() {
        super.mounted();
    }

    resize_or_scroll() {
        let item = document.getElementById('scroll-end');
        let item_offset = getOffset(item);

        if (!item_offset) {
            return;
        }

        let elementTop = item_offset.top;

        let viewportTop = window.scrollY;
        let viewportBottom = viewportTop + window.innerHeight;

        let visible = elementTop < viewportBottom + 1500;

        if (visible) {
            if (this.viewTimeout) {
                clearTimeout(this.viewTimeout);
            }

            this.viewTimeout = setTimeout(() => {
                this.offset += 24;
                this.loadProducts();
            }, 100);
        }

        // This is really really really bad for performance but we need to have inView() update so we don't
        // try to load 100+ canvases at once.
        this.$forceUpdate();
    };

    abstract loadProducts();

    @method()
    recommendedProductForIndex(index) {
        return this.products.items[index % this.products.items.length];
    }

    @method()
    groupForIndex(index) {
        return this.groups[this.recommendedProductForIndex(index).group.id];
    }

    @method()
    inView(index) {
        let element = document.getElementById(`rp-${index}`);
        let offset = getOffset(element);
        if (!offset) {
            return false;
        }

        let elementTop = offset.top;
        let elementBottom = elementTop + getInnerHeight(element);

        let viewportTop = window.scrollY;
        let viewportBottom = viewportTop + window.innerHeight;

        return elementBottom > viewportTop - 1000 && elementTop < viewportBottom + 1000;
    }
}