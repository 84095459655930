export const html: string = `
<div class="recommended-product">
    <div class="preview-area" :style="{height: canvas_container_size ? canvas_container_size + 'px' : null}">
        <canvas width="100%"></canvas>
    </div>
    <div class="swap-area">
        <div class="swap-icon" v-if="swaps.length > 1" v-for="swap in swaps" :class="{selected: swap == recommendedProduct}" @click="swapTo(swap)">
            <img :src="swap.icon">
        </div>
    </div>
    <div class="product-name">
        <label>{{ name ? name + ' ' : '' }}{{ product_name }}</label>
    </div>
    <div class="options-area">
        <div class="option-label">
            Price
        </div>
        <div class="option-value">
            {{ size && size.price ? $filters.currency(size.price.value, size.currency) : '' }}
        </div>
    </div>
    <div class="options-area">
        <div class="option-label" v-if="recommendedProduct && !recommendedProduct.disable_colors">
            Color
        </div>
        <div class="option-value" v-if="recommendedProduct && !recommendedProduct.disable_colors">
            <div class="color-options">
                <generic-color-dropdown class="smaller-dropdown" :items="colors" v-model:selected="color" :trigger-function="updateRegionsCallback"></generic-color-dropdown>
            </div>
        </div>
    </div>
    <div class="options-area">
        <div class="option-label">
            Size
        </div>
        <div class="option-value">
            <generic-dropdown class="smaller-dropdown" :items="sizes" v-model:selected="size" :trigger-function="updateSizeCallback"></generic-dropdown>
        </div>
    </div>
    <div class="options-area">
        <div class="option-label">
            Quantity
        </div>
        <div class="option-value">
            <input type="number" step="1" v-model="quantity">
        </div>
    </div>
    <div class="actions-area clearfix">
        <div class="action-item">
            <a class="btn gray-btn btn-sm" :class="{'btn-success': added_to_cart}" @click="addToCart($event)" v-show="!added_to_cart">Add To Cart</a>
            <a class="btn gray-btn btn-sm" :class="{'btn-success': added_to_cart}" v-show="added_to_cart"><i class="fa fa-check"></i>Added</a>
        </div>
        <div class="action-item">
            <a class="btn gray-btn btn-sm" @click="goToEdit($event)">Edit Design</a>
        </div>
    </div>
</div>
`;
