
export enum TextAlignment {
    LEFT,
    CENTER,
    RIGHT
}

export enum RegionType {
    FILL,
    STROKE
}

export enum DynamicBackgroundShape {
    CIRCLE,
    RECTANGLE,
    ELLIPSE,
    CONTOUR
}

export enum ToolTypeEnum {
    EASY,
    ADVANCED,
    UPLOAD,
    PREVIEW,
    LETTERING
}

export enum ProductTypeEnum {
    CUTOUT,
    PRINTED,
    PRINTED_CUTOUT,
    MULTICOLOR_CUTOUT
}

export enum SymbolType {
    NONE,
    NUMBER_RANGE,
    VARIABLE_DATA,
    CSV_LIST
}

export enum CustomLamination {
    NONE,
    MATTE,
    GLOSS
}

export enum DisplayTypeEnum {
    DROPDOWN,
    RADIO,
    CHECKBOX,
    FIXED_VALUE
}

export enum ProofApproval {
    AS_IS,
    CHANGES
}

export enum ClipTypeEnum{
    NONE,
    CUSTOM_PATH,
    RECTANGLE,
    ELLIPSE,
    ROUNDED_CORNER,
    CIRCLE,
    CONTOUR
}