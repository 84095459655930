import RecommendedProductComponent from './RecommendedProduct/RecommendedProductComponent';
import RecommendedProductBrowse from './RecommendedProductBrowse/RecommendedProductBrowse';
import RecommendedProductList from './RecommendedProductList/RecommendedProductList';
import RelatedProducts from './RelatedProducts/RelatedProducts';
import TrendingProducts from './TrendingProducts/TrendingProducts';
import RecommendedProductPersonalized from './RecommendedProductPersonalized/RecommendedProductPersonalized';
import RecommendedProductCategory from './RecommendedProductCategory/RecommendedProductCategory';
import VueApp from '../../../core/adapters/VueApp';


export default function registerComponents(vue_app: VueApp) {
    vue_app.registerComponent(RecommendedProductComponent);
    vue_app.registerComponent(RecommendedProductBrowse);
    vue_app.registerComponent(RecommendedProductPersonalized)
    vue_app.registerComponent(RecommendedProductList);
    vue_app.registerComponent(RecommendedProductCategory);
    vue_app.registerComponent(RelatedProducts);
    vue_app.registerComponent(TrendingProducts);
}