import {html} from './RecommendedProductComponent.html';
import {RecommendedProduct} from '../../../svgcustom/models/RecommendedProduct';
import {TemplateSize} from '../../../svgcustom/models/TemplateSize';
import ButtonProcessingState from '../../../../core/utils/ButtonProcessingState';
import VueComponent, {data, method, prop} from '../../../../core/adapters/VueComponent';
import {RegionType} from '../../../svgcustom/models/enums';
import {Color} from '../../../svgcustom/models/Color';
import {Clipart, EClipartColorChoiceEnum} from '../../../svgcustom/models/Clipart';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {getInnerWidth} from '../../../../core/utils/utils';
import {Services} from '../../../../core/services/Services';
import CartService from '../../../cart/services/CartService';
import {IDeferred, SimplePromise} from '../../../../core/utils/SimplePromise';
import SeedRandom from '../../../../core/utils/SeedRandom';
import * as paper from 'paper';
import {disableVueForImport} from '../../../../core/adapters/VueUtils';

export class RecommendedProductController extends VueComponent {
    static override $inject = [
        '$http',
        'Color',
        'Clipart',
    ];

    @prop()
    contentType: string;

    @prop()
    objectId: string;

    @prop()
    design: string;

    @prop()
    defaultRecommendedProduct: RecommendedProduct;

    @prop()
    randomColor: boolean;

    @prop()
    seed: number;

    @data()
    recommendedProduct: RecommendedProduct;

    @prop()
    swaps: any;

    @prop()
    name;

    // Product Options
    @data()
    sizes;

    @data()
    quantity;

    @data()
    product_name;

    @data()
    added_to_cart: boolean;

    @data()
    colors: any[];

    @data()
    color: any;

    @data()
    updateRegionsCallback: Function;

    @data()
    updateSizeCallback: Function;

    @data()
    size: TemplateSize;

    @data()
    canvas_container_size: number;

    paper_import;
    paper;

    // Selection Options
    protected clipart;

    // Paper Canvas
    protected paperScope: paper.PaperScope;
    protected canvas;
    public project: paper.Project;
    protected layer: paper.Layer;
    protected canvas_width: number;
    protected canvas_height: number;
    protected design_raster: paper.Raster;
    protected design_layer: paper.Layer;
    protected product_clipart: paper.Item;
    protected product_overlay: paper.Item;
    protected product_clipping_mask: paper.Item;
    protected configured: IDeferred<null>;
    protected destroyed: boolean;
    protected rand;

    protected error: string;

    constructor(component,
                protected $http,
                protected ColorModel: typeof Color) {
        super(component);

        this.paper_import = import('paper');
        this.paper_import.then((paper) => {
            disableVueForImport(paper);
            this.paper = paper;
        });

        this.updateRegionsCallback = this.updateRegions.bind(this);
        this.updateSizeCallback = this.onUpdateSize.bind(this);

        this.recommendedProduct = this.defaultRecommendedProduct;

        this.rand = new SeedRandom(this.seed);

        this.setup();
    }


    override unmounted() {
        super.unmounted();

        // Try to clean up memory usage
        this.destroyed = true;
        this.project?.clear();
        this.project?.remove();
    }

    filterColors() {
        this.colors = [];
        for (let c of this.recommendedProduct.colors) {
            if (this.size && this.size.disabled_colors.getItemFromID(c.id)) {
                continue
            }

            this.colors.push(c);
        }
    }

    setup() {
        this.clipart = this.recommendedProduct.clipart;
        this.sizes = this.recommendedProduct.sizes;

        // Defaults
        this.quantity = this.quantity || 1;
        if (this.randomColor) {
            this.color = this.recommendedProduct.colors[Math.floor(this.recommendedProduct.colors.length * this.rand.random())];
        }
        else {
            if (this.recommendedProduct.default_color) {
                for (const color of this.recommendedProduct.colors) {
                    if (color.id == this.recommendedProduct.default_color.id) {
                        this.color = color;
                    }
                }
            }

            if (!this.color) {
                for (const color of this.recommendedProduct.colors) {
                    if (color.default) {
                        this.color = color;
                    }
                }

                if (!this.color) {
                    this.color = this.recommendedProduct.colors[0];
                }
            }
        }
        this.size = this.sizes[0];
        this.product_name = this.recommendedProduct.product_name;

        if (!this.configured) {
            this.configured = SimplePromise.defer();
        }

        this.configured.promise.then(this.setupBackground.bind(this));
        this.configured.promise.then(this.setupClipart.bind(this));

        this.onUpdateSize();
    }

    reset() {
        this.paperScope.activate();
        this.design_layer?.remove();
        this.design_layer = new this.paper.Layer();
        this.layer?.remove();
        this.layer = new this.paper.Layer();
        this.layer.addChild(this.design_layer);
        this.design_raster?.remove();
        this.design_raster = null;
        this.product_clipart?.remove();
        this.product_clipart = null;

        this.setup();
    }

    @method()
    swapTo(product) {
        this.recommendedProduct = product;
        this.filterColors();

        this.canvas_width = this.canvas_height * (this.recommendedProduct.canvas_width / this.recommendedProduct.canvas_height);

        if (this.recommendedProduct.canvas_width > this.recommendedProduct.canvas_height) {
            this.canvas_width = this.canvas_container_size;
            this.canvas_height = this.canvas_container_size * (this.recommendedProduct.canvas_height / this.recommendedProduct.canvas_width);
        }
        else {
            this.canvas_height = this.canvas_container_size;
            this.canvas_width = this.canvas_container_size * (this.recommendedProduct.canvas_width / this.recommendedProduct.canvas_height);
        }

        this.canvas.style.width = Number(this.canvas_width).toFixed(0) + 'px';
        this.canvas.style.height = Number(this.canvas_height).toFixed(0) + 'px';
        this.paperScope.view.viewSize = new this.paper.Size(this.canvas_width, this.canvas_height);

        this.reset();
    }

    onUpdateSize() {
        this.filterColors();
        if (this.size.disabled_colors.getItemFromID(this.color.id)) {
            this.color = this.colors[0];
            this.updateRegions();
        }
    }

    @method()
    addToCart($event) {
        const processing = new ButtonProcessingState($event);
        processing.process();

        this.$http.request({
            url: '/custom/api/v1/create-product-from-recommendation/',
            method: 'POST',
            data: {
                recommended_product_id: this.recommendedProduct.id,
                color_id: this.color.id,
                size_id: this.size.id,
                svg: new XMLSerializer().serializeToString(this.paperScope.project.exportSVG() as any),
                img: this.canvas.toDataURL('image/png'),
                design_content_type: this.contentType,
                design_object_id: parseInt(this.objectId),
                add_to_cart: true
            }
        }).then((response) => {
            processing.resolved();
            if (response.data.success) {
                this.added_to_cart = true;
            }
            else {
                this.added_to_cart = false;
                this.error = response.data.error;
            }
            if (window.location.pathname == '/cart/') {
                // Cart service will be registered on that page
                Services.get<CartService>('CartService').softFetchCartLines();
            }

        }, (response) => {
            processing.resolved();
            this.added_to_cart = false;
            this.error = response.data.error;
        });
    }

    @method()
    goToEdit($event) {
        const processing = new ButtonProcessingState($event);
        processing.process();

        this.$http.request({
            url: '/custom/api/v1/create-product-from-recommendation/',
            method: 'POST',
            data: {
                recommended_product_id: this.recommendedProduct.id,
                color_id: this.color.id,
                size_id: this.size.id,
                svg: new XMLSerializer().serializeToString(this.paperScope.project.exportSVG() as any),
                design_content_type: this.contentType,
                design_object_id: this.objectId,
                add_to_cart: false
            }
        }).then((response) => {
            processing.resolved();
            if (response.data.success) {
                window.location.assign(response.data.url);
            }
            else {
                this.error = response.data.error;
            }
        }, (response) => {
            processing.resolved();
            this.error = response.data?.error;
        });
    }

    override async mounted() {
        if (!this.configured) {
            this.configured = SimplePromise.defer();
        }

        await this.paper_import;

        this.canvas = this.$el.querySelector('canvas');
        this.canvas_container_size = getInnerWidth(this.canvas);

        if (this.recommendedProduct.canvas_width > this.recommendedProduct.canvas_height) {
            this.canvas_width = getInnerWidth(this.canvas);
            this.canvas_height = this.canvas_width * (this.recommendedProduct.canvas_height / this.recommendedProduct.canvas_width);
        }
        else {
            this.canvas_height = getInnerWidth(this.canvas);
            this.canvas_width = this.canvas_height * (this.recommendedProduct.canvas_width / this.recommendedProduct.canvas_height);
        }

        this.canvas.style.width = Number(this.canvas_width).toFixed(0) + 'px';
        this.canvas.style.height = Number(this.canvas_height).toFixed(0) + 'px';

        this.paperScope = new this.paper.PaperScope();
        this.paperScope.activate();
        this.paperScope.setup(this.canvas);
        this.paperScope.view.viewSize = new this.paper.Size(this.canvas_width, this.canvas_height);

        this.project = this.paperScope.project;

        this.design_layer = new this.paper.Layer();
        this.layer = new this.paper.Layer();
        this.layer.addChild(this.design_layer);

        // Setup high quality down sampling for better scaling
        let ctx = this.paperScope.view.element.getContext('2d');
        ctx.imageSmoothingQuality = 'high';
        ctx.imageSmoothingEnabled = true;

        this.configured.resolve(null);
    }

    setupBackground() {
        if (this.design.indexOf('.svg') != -1) {
            this.layer.importSVG(this.design, {
                onLoad: (item, content) => {
                    if (this.destroyed) {
                        return;
                    }

                    this.design_raster = item;

                    let x = this.canvas_width * (this.recommendedProduct.x / 100);
                    let y = this.canvas_height * (this.recommendedProduct.y / 100);
                    let w = this.canvas_width * (this.recommendedProduct.width / 100);
                    let h = this.canvas_height * (this.recommendedProduct.height / 100);

                    let bounds = new this.paper.Rectangle(
                        new this.paper.Point(x,y),
                        new this.paper.Size(w,h)
                    )

                    this.design_raster.fitBounds(bounds);
                    this.removeCutContour();
                },
                onError: (message, status) => {
                    // This is needed to make sure the error propagates correctly
                    throw message
                }
            });
        }
        else {
            this.layer.activate();
            this.design_raster = new this.paper.Raster({
                source: this.design,
                crossOrigin: 'anonymous',
            });
            this.design_raster.on('load', () => {
                if (this.destroyed) {
                    return;
                }

                let x = this.canvas_width * (this.recommendedProduct.x / 100);
                let y = this.canvas_height * (this.recommendedProduct.y / 100);
                let w = this.canvas_width * (this.recommendedProduct.width / 100);
                let h = this.canvas_height * (this.recommendedProduct.height / 100);

                let bounds = new this.paper.Rectangle(
                    new this.paper.Point(x,y),
                    new this.paper.Size(w,h)
                )

                this.design_raster.fitBounds(bounds);
                this.design_layer.addChild(this.design_raster);
            });
        }
        this.layer.addChild(this.design_raster);
    }

    setupClipart() {
        this.layer.importSVG(this.clipart.clipart, {
            onLoad: (item, contents) => {
                if (this.destroyed) {
                    return;
                }

                this.product_clipart = item;
                (this.product_clipart as any).fitBounds(0, 0, this.canvas_width, this.canvas_height);
                this.layer.addChild(this.product_clipart);
                this.product_clipart.sendToBack();
                this.product_overlay?.bringToFront();
                this.updateRegions();
            },
            // This will be spammed if the component is unloaded before its able to import so lets ignore it.
            onError: (message, status) => {}
        });
        if (this.recommendedProduct && this.recommendedProduct.overlay) {
            this.layer.importSVG(this.recommendedProduct.overlay.clipart, {
                onLoad: (item, contents) => {
                    if (this.destroyed) {
                        return;
                    }

                    this.product_overlay = item;
                    (this.product_overlay as any).fitBounds(0, 0, this.canvas_width, this.canvas_height);
                    this.layer.addChild(this.product_overlay);
                    this.product_overlay.bringToFront();
                    this.updateRegions();
                },
                // This will be spammed if the component is unloaded before its able to import so lets ignore it.
                onError: (message, status) => {}
            });
        }
        if (this.recommendedProduct && this.recommendedProduct.clipping_mask) {
            this.layer.importSVG(this.recommendedProduct.clipping_mask.clipart, {
                onLoad: (item, contents) => {
                    if (this.destroyed) {
                        return;
                    }

                    if (item.children[0].type == 'rectangle') {
                        item.children[0].remove();
                    }

                    this.product_clipping_mask = item;
                    (this.product_clipping_mask as any).fitBounds(0, 0, this.canvas_width, this.canvas_height);
                    
                    if (this.recommendedProduct.clipping_mask.colors == EClipartColorChoiceEnum.SINGLE_COLOR && item.children.length > 1) {
                        if (item.children[0].type == 'rectangle') {
                            item.children[0].remove();
                        }
                    }

                    this.design_layer.addChild(this.product_clipping_mask);
                    this.product_clipping_mask.clipMask = true;
                    this.product_overlay?.bringToFront();
                    this.updateRegions();
                },
                // This will be spammed if the component is unloaded before its able to import so lets ignore it.
                onError: (message, status) => {}
            });
        }
    }

    removeCutContour() {
        const toRemove: paper.Item[] = [];
        this.design_raster.getItems({
            match: (i: paper.Item): void => {
                if (i.name === 'cut-contour') {
                    toRemove.push(i);
                }
            }
        });
        for (const item of toRemove)
            item.remove();
    }

    @method()
    updateRegions() {
        if (!this.product_clipart) {
            return;
        }

        if (!this.clipart.regions) {
            return;
        }

        // Update regions
        for (const region of this.clipart.regions) {
            // Search for the item with the region ID
            const items: paper.Item[] = this.product_clipart.getItems({
                match: (i) => {
                    return region.selectorId.indexOf(i.name) > -1 || region.selectorId.indexOf(i.className.toLowerCase()) > -1;
                }
            });

            // There could be multiple items, so let's update them all
            for (const item of items) {
                if (region.type === RegionType.FILL) {
                    if (!region.color) {
                        item.fillColor = null;
                        item.visible = false;
                    } else {
                        item.fillColor = this.color.fillValue;
                        item.visible = true;

                        if (region.blend_mode_selector && this.color.blend_mode) {
                            const blend_items: paper.Item[] = this.product_clipart.getItems({
                                match: (i) => {
                                    return region.blendSelectorId.indexOf(i.name) > -1 || region.blendSelectorId.indexOf(i.className.toLowerCase()) > -1;
                                }
                            });
                            for (const blend_item of blend_items) {
                                blend_item.blendMode = this.color.blend_mode;
                            }
                        }
                        else {
                            item.blendMode = 'normal'
                        }
                    }
                } else {
                    if (region.color) {
                        item.strokeColor = this.color.fillValue;
                        item.strokeWidth = region.stroke_width;
                        item.visible = true;
                    }
                    else {
                        item.visible = false;
                    }
                }
            }
        }
    }
}

export default function RecommendedProductComponent(): IVueComponent {

    return {
        controller: RecommendedProductController,
        template: html,
        tag: 'recommended-product-component'
    };
}
