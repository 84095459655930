import StepsIndicator from './Indicator/StepsIndicator';
import Price from './Price/Price';
import ProductPageNavigator from './ProductPageNavigator/ProductPageNavigator';
import QuantityOptions from './QuantityOptions/QuantityOptions';
import VueApp from '../../../core/adapters/VueApp';


export default function registerComponents(vue_app: VueApp) {
    // Add all login components to core, its loaded on every page
    vue_app.registerComponent(StepsIndicator);
    vue_app.registerComponent(Price);
    vue_app.registerComponent(ProductPageNavigator);
    vue_app.registerComponent(QuantityOptions);
}