import {Collection} from '../Collection';
import {Model} from '../Model';
import {ToManyField} from './ToManyField';
import {ResourceURI} from '../helpers/ResourceURI';
import {SimplePromise} from '../../utils/SimplePromise';

export type TToManyKeyURI<T extends Model> = Collection<T> | T[] | string[];

export class ToManyURIField extends ToManyField {
    public resourceUris: ResourceURI[];
    private _request: any;

    override set value(data: any) {
        this.resourceUris = [];
        if (data == null) {
            this.setValue(null);
        } else {
            const toSuper: Collection<any> = new this.relatedModel.collectionClass();
            for (const d of data) {
                if (typeof d === 'string') {
                    this.resourceUris.push(new ResourceURI(d));
                } else {
                    if (d instanceof this.relatedModel) {
                        toSuper.push(d);
                    } else {
                        toSuper.push(new this.relatedModel(d));
                    }
                }
            }

            if (toSuper.length > 0)
                super.setValue(toSuper);

            if (this.config.load)
                this.load();
        }
    }

    override getPostData() {
        // Just a list of string URIs?
        if (!this.value || (this.value.length === 0 && this.resourceUris.length > 0)) {
            const uris: string[] = [];
            for (const uri of this.resourceUris) {
                uris.push(uri.uri);
            }
            return uris;
        }

        return super.getPostData();
    }

    override get value() {
        return this.getValue();
    }

    get ids(): number[] {
        const ids: number[] = [];

        if (this.value && this.value.length > 0) {
            for (const m of this.value) {
                ids.push(m.id);
            }
        } else {
            for (const uri of this.resourceUris) {
                ids.push(uri.id);
            }
        }

        return ids;
    }

    load() {
        const ids: number[] = this.ids;
        if (ids.length > 0) {
            const request = this.relatedModel.objects.filter({
                id__in: ids.join(',')
            });

            request.$promise.then(() => {
                this.value = request.items;
            });
            return request;
        } else {
            this.reset();
            return SimplePromise.resolve(this);
        }
    }

     get $promise() {
         if (this._request)
             return;
     }
}
