import {html} from './ProductPageNavigator.html';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import VueComponent, {data, method, prop} from '../../../../core/adapters/VueComponent';
import {getInnerHeight} from '../../../../core/utils/utils';

class ProductPageNavigatorController extends VueComponent {

    @data()
    fixed;

    @prop()
    mainContainer: string;

    @data()
    active_selections: any[];

    public static readonly PageSections: any[] = [
        {element_id: 'recommended-products-component', element_title: 'Recommended Products'},
        {element_id: 'product-image-description-component', element_title: 'Product'},
        {element_id: 'featured-video-component', element_title: 'Featured Video'},
        {element_id: 'trending-products-component', element_title: 'Trending Products'},
        {element_id: 'reviews', element_title: 'Featured Testimonial'},
        {element_id: 'product-gallery-component', element_title: 'Product Gallery'},
        {element_id: 'product-features', element_title: 'Product Features'},
        {element_id: 'product-video', element_title: 'Product Video'},
        {element_id: 'contact-us', element_title: 'Contact Us'},
        {element_id: 'product-faq-list-app', element_title: 'FAQs'}
    ];

    constructor(component) {
        super(component);

        this.active_selections = [];
    }

    checkFixedNavVisibility() {
        let container = document.getElementById(this.mainContainer)
        let absolute_position = getInnerHeight(container) + container.offsetTop - getInnerHeight(this.$el);
        this.fixed = absolute_position - window.scrollY < 0;
    }

    checkActiveItem() {
        for (const item of this.active_selections) {
            let top_position = item.el.offsetTop;
            let bottom_position = item.el.offsetTop + getInnerHeight(item.el);

            // If the scroll position is with in the bounds of the element
            item.active = top_position <= window.scrollY && window.scrollY <= bottom_position;
        }
    }

    override mounted() {
        window.addEventListener("scroll", this.checkFixedNavVisibility.bind(this), {passive: true});
        window.addEventListener("scroll", this.checkActiveItem.bind(this), {passive: true});

        for (const item of (this.constructor as typeof ProductPageNavigatorController).PageSections) {
            let qry = document.getElementById(item.element_id);
            if (qry) {
                this.active_selections.push({
                    el: qry,
                    active: false,
                    base: item,
                    position: qry.offsetTop
                })
            }
        }

        this.active_selections.sort((a,b) => {
            return a.position - b.position;
        })
    }

    @method()
    setActiveLink(item) {
        item.active = true;
        item.el.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
}

export default function ProductPageNavigator(): IVueComponent {

    return {
        controller: ProductPageNavigatorController,
        template: html,
        tag: 'product-page-navigator'
    };
}
