import {html} from './RecommendedProductBrowse.html';
import {Http} from '../../../../core/services/Http';
import {method} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {RecommendedProductAbstract} from './RecommendedProductAbstract';
import {Services} from '../../../../core/services/Services';

export class RecommendedProductBrowseController extends RecommendedProductAbstract {

    @method()
    override loadProducts() {
        Services.get<Http>('$http').request({
            url: '/custom/api/v1/stock-products-to-recommend/',
            method: 'GET',
            params: {
                offset: this.offset,
                limit: 20
            },
        }).then((response: any) => {
            for (const item of response.data.data) {
                this.customer_products.push(item);
            }
        }, (error) => {
            return error;
        });
    }
}

export default function RecommendedProductBrowse(): IVueComponent {

    return {
        controller: RecommendedProductBrowseController,
        template: html,
        tag: 'recommended-product-browse'
    };
}
