import {html} from "./StepsIndicator.html"
import VueComponent, {prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';

class IndicatorController extends VueComponent {
    @prop()
    step: number;

    @prop()
    one: string;

    @prop()
    two: string;

    @prop()
    three: string;
}

export default function StepsIndicator(): IVueComponent {

    return {
        controller: IndicatorController,
        template: html,
        tag: 'steps-indicator'
    };
}
