import {html} from './Price.html';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import VueComponent, {prop} from '../../../../core/adapters/VueComponent';
import {ConfiguredProductAbstract} from '../../../svgcustom/models/abstract/ConfiguredProductAbstract';

class PriceController extends VueComponent {
    @prop()
    productService: ConfiguredProductAbstract;

    @prop()
    unit: boolean;

    @prop()
    total: boolean;

    constructor(component) {
        super(component);

        // Inefficient but we need to make sure this is always up to date
        this.productService.bind('change:price', this.$forceUpdate.bind(this));
    }
}

export default function Price(): IVueComponent {

    return {
        controller: PriceController,
        template: html,
        tag: 'price'
    };
}
