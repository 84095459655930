import {Http} from '../../../../core/services/Http';
import {method} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {Services} from '../../../../core/services/Services';
import {RecommendedProductAbstract} from '../RecommendedProductBrowse/RecommendedProductAbstract';
import {html} from './RecommendedProductCategory.html';
import RouteService from '../../../../core/services/RouteService';

export class RecommendedProductCategoryController extends RecommendedProductAbstract {
    base_customer_products: [];
    loaded;

    category_id: number;

    constructor(component) {
        super(component);

        this.category_id = Number(Services.get<RouteService>('RouteService').current.params.get('category'));

        Services.get<Http>('$http').request({
            url: '/custom/api/v1/category-products-to-recommend/',
            method: 'GET',
            params: {
                category_id: this.category_id
            }
        }).then((response) => {
            this.base_customer_products = response.data.data;
            this.customer_products = [];
            this.loadProducts();
        });
    }

    @method()
    override loadProducts() {
        if (!this.customer_products || !this.base_customer_products) {
            return;
        }

        while (this.offset > this.customer_products.length) {
            this.customer_products.push(this.base_customer_products[Math.floor(Math.random() * this.base_customer_products.length)]);
        }

        if (!this.loaded) {
            // Idk why this needs to be done in a timeout. It might be a bug with vue
            setTimeout(() => {
                this.$forceUpdate();
            });

            this.loaded = true;
        }
    }
}

export default function RecommendedProductCategory(): IVueComponent {

    return {
        controller: RecommendedProductCategoryController,
        template: html,
        tag: 'recommended-product-category'
    };
}
