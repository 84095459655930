import {html} from './QuantityOptions.html';
import VueComponent, {computed, data, method, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import ConfiguredProductServiceAbstract from '../../services/ConfiguredProductServiceAbstract';

export class QuantityOptionsController extends VueComponent {
    private MAX_ITEMS = 6;

    @prop()
    productService: ConfiguredProductServiceAbstract;

    @prop()
    hidePrice: boolean;

    @prop()
    disableRangeRemoval: boolean;

    @data()
    show_more_quantities: boolean;

    @data()
    show_more_qty_options: boolean;

    @data()
    quantity_options: any[];

    @prop()
    full: boolean;

    constructor(component) {
        super(component);

        this.show_more_quantities = false;
        this.MAX_ITEMS = 6;

        if (this.productService) {
            this.productService.bind('change:price', () => {
                this.quantity_options = this.calcQuantityOptions();
            });
            this.productService.setup_complete.then(() => {
                this.quantity_options = this.calcQuantityOptions();
            })
        }
        else {
            console.error('Undefined product service');
        }
        this.updateQuantityOptions();
    }

    @method()
    updateQuantityOptions() {
        this.quantity_options = this.calcQuantityOptions();
    }

    calcQuantityOptions(): any[] {
        if (!this.productService || !this.productService.pricing) {
            return [];
        }

        let pricing = this.productService.pricing;

        // Pop off all of the quantites that are lower than the min quantity and alter lower bracket to be the min
        let to_pop = [];
        for (let i = 0; i < pricing.length-1; i++) {
            let current = pricing[i];
            let next = pricing[i+1];

            if (current.quantity <= this.productService.base.minimum_quantity) {
                if (next.quantity <= this.productService.base.minimum_quantity) {
                    to_pop.push(current);
                }
                else {
                    current.quantity = this.productService.base.minimum_quantity;
                }
            }
        }

        for (const item of to_pop) {
            pricing.splice(pricing.indexOf(item), 1);
        }

        this.show_more_qty_options = pricing.length > this.MAX_ITEMS;

        if (this.show_more_quantities || pricing.length <= this.MAX_ITEMS) {
            if (pricing.length == 1) {
                return  [];
            }

            return pricing;
        }

        const mod = Math.floor(pricing.length / this.MAX_ITEMS);

        let items = [];
        let quantity_in_options = false;
        for (let i = 0; i < this.MAX_ITEMS; i++) {
            let price = pricing[i * mod];

            items.push(price);

            if (price.quantity === this.productService.configured.quantity) {
                quantity_in_options = true;
            }
        }

        // Always show the last item
        items[items.length - 1] = pricing[pricing.length - 1];
        if (items[items.length - 1].quantity === this.productService.configured.quantity) {
            quantity_in_options = true;
        }

        // If the currently selected item was not in the list, remove the second to last one and place it in the list
        // to allow us to still show the max
        if (!quantity_in_options) {
            for (let i = 0; i < pricing.length; i++) {
                const price = pricing[i];
                if (price.quantity === this.productService.configured.quantity) {
                    items[items.length - 2] = price;
                    items.sort((a, b) => {return a.quantity - b.quantity});
                    break;
                }
            }
        }

        if (items.length == 1) {
            items = [];
        }

        return items;
    }

    @method()
    stackedRange() {
        return this.productService.configured?.['quantity_model']?.symbol_range_start?.length > 20 || this.productService.configured?.['quantity_model']?.symbol_range_end?.length > 20
    }
}

export default function QuantityOptions(): IVueComponent {
    return {
        controller: QuantityOptionsController,
        template: html,
        tag: 'quantity-options'
    };
}