import {Field} from './Field';
import {toFixedFloor} from '../../utils/utils';

export class FloatField extends Field {
    override clean(): number {
        let data = this.value;
        return this.parseInput(data);
    }

    private parseInput(data) {
        if (typeof(data) === 'string') {
            data = parseFloat(data);
        }

        if (typeof(data) === 'number' && this.config.toFixed) {
            data = toFixedFloor(data, this.config.toFixed);
        }

        return data;
    }

    public override validate(): string[] {
        this._errors = super.validate();

        if (this.skipValidation) {
            return this._errors;
        }

        if (isNaN(this.cleaned))
            this._errors.push('Invalid numeric value.');

        return this._errors;
    }

    protected override initialValueSetup(v) {
        return this.parseInput(v);
    }
}
