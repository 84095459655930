export const html: string = `
<div id="recommended-products-list" class="recommended-product-list">
    <div class="base-carousel-slider">
        <hr v-if="!hideHr && isVisible()">
        <h2 class="text-center" :style="{'margin-top': hideHr ? '0' : '' }" v-if="isVisible()">{{ nameOverride ? nameOverride : 'Add Your Design To These Additional Products!' }}</h2>
        <div class="slider-container" v-if="isVisible()">
            <ul class="carousel-slider no-scrollbar">
                <li v-for="(group, $index) in groups" class="related-product-item">
                    <recommended-product-component 
                        :swaps="group.products" 
                        :default-recommended-product="group.products[0]" 
                        :design="customerProductForIndex($index).design" 
                        :content-type="customerProductForIndex($index).contentType" 
                        :object-id="customerProductForIndex($index).objectId">    
                    </recommended-product-component>    
                </li>
            </ul>
        </div>
        <span class="glyphicon glyphicon-chevron-left" 
              @click="carousel_controller.scrollSliderLeft($event)" 
              aria-hidden="true" 
              :class="{'disabled': carousel_controller.maxLeft()}" 
              v-if="isVisible()">
        </span>
        <span class="glyphicon glyphicon-chevron-right" 
              @click="carousel_controller.scrollSliderRight($event)" 
              aria-hidden="true" 
              :class="{'disabled': carousel_controller.maxRight()}" 
              v-if="isVisible()">      
        </span>
    </div>
</div>
`;