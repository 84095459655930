export const html:string = `
<div id="recommended-products-component">
    <h2>Recommended Products</h2>
    <div class="carousel-slider">
        <div class="slider-container">
            <ul class="carousel-slider no-scrollbar" v-if="product_service.related_products">
                <li v-for="product in product_service.related_products.items" class="related-product-item">
                    <a :href="product.url" :title="product.name" class="product-image">
                        <img :src="product.thumbnail" :alt="product.name" v-if="!product.thumbnail_webp"/>
                        <picture v-if="!!product.thumbnail_webp">
                            <source :srcset="product.thumbnail_webp" type="image/webp">
                            <img :src="product.thumbnail" :alt="product.name"/>
                        </picture>
                    </a>
                    <div class="product-details">
                        <a :href="product.url" class="product-name" :title="product.name">
                            {{ product.name }}
                        </a>
                    </div>
                </li>
            </ul>
        </div>
        <span class="glyphicon glyphicon-chevron-left" @click="carousel_controller.scrollSliderLeft($event)" aria-hidden="true" :class="{'disabled': carousel_controller.maxLeft()}"></span>
        <span class="glyphicon glyphicon-chevron-right" @click="carousel_controller.scrollSliderRight($event)" aria-hidden="true" :class="{'disabled': carousel_controller.maxRight()}"></span>
    </div>
</div>
`;
