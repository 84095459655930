export const html:string = `
<div class="customize-steps">
    <ul>
        <li :class="{'completed': step > 1}">{{ one ? one : 'PRODUCT DETAILS' }}</li>
        <li :class="{'completed': step > 2}">{{ two ? two : 'DESIGN' }}</li>
        <li :class="{'completed': step > 3}">{{ three ? three : 'FINALIZE PRODUCT' }}</li>
    </ul>
    <ul class="graphic">
        <li :class="{'completed': step > 1}">
            <i class="fa fa-check"></i>
        </li>
        <li :class="{'completed': step > 2}">
            <span></span>
            <i class="fa fa-check"></i>
        </li>
        <li :class="{'completed': step > 3}">
            <span></span>
            <i class="fa fa-check"></i>
        </li>
    </ul>
</div>
`;
