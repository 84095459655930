/*
    A basic implementation of multiply with carry pseudorandom number generator
*/

export default class SeedRandom {
    m_w = 123456789;
    m_z = 987654321;
    mask = 0xffffffff;

    constructor(start_seed?) {
        this.seed(start_seed);
    }

    seed(i) {
        if (i == null) {
            i = Math.round(Math.random() * 1000);
        }
        this.m_w = (123456789 + i) & this.mask;
        this.m_z = (987654321 - i) & this.mask;
    }

    random() {
        this.m_z = (36969 * (this.m_z & 65535) + (this.m_z >> 16)) & this.mask;
        this.m_w = (18000 * (this.m_w & 65535) + (this.m_w >> 16)) & this.mask;
        let result = ((this.m_z << 16) + (this.m_w & 65535)) >>> 0;
        result /= 4294967296;
        return result;
    }
}