import {html} from './RelatedProducts.html';
import VueComponent, {data, prop} from '../../../../core/adapters/VueComponent';
import CarouselController from '../../../../core/utils/CarouselController';
import {Services} from '../../../../core/services/Services';
import IVueComponent from '../../../../core/adapters/IVueComponent';

export class RelatedProductsController extends VueComponent {
    @data()
    carousel_controller: CarouselController;

    @prop()
    productServiceImport;

    @data()
    product_service;

    constructor(component) {
        super(component);
        this.carousel_controller = new CarouselController(this);
    }

    override beforeMount() {
        if (this.$props.productServiceImport) {
            this.product_service = Services.get(this.$props.productServiceImport);
        }
        else {
            console.error('The product service import was not defined. The related products will not load correctly.')
        }
    }

    override mounted() {
        super.mounted();
        this.carousel_controller.mounted();
    }

    override unmounted() {
        super.unmounted();
        this.carousel_controller.unmounted();
    }
}

export default function RelatedProducts(): IVueComponent {

    return {
        controller: RelatedProductsController,
        template: html,
        tag: 'related-products'
    };
}
